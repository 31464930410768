import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { SKUCard } from './common/SKUDecoder'
import { OutlinedTextField } from './common/fields'
import { RespButton } from './common/buttons'
import GenericDialog from './common/GenericDialog'
import { Typography, ButtonBase } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import { adjustColor } from './common/utils'

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: '8px 24px 2px',
    display:'flex',
    flexDirection: 'column',
    gap: 20,
  },
  firstStepContainer: {
    paddingTop: 8,
    display:'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems:'center',
    gap: 25,
  },
  circularProgressContainer: {
    width: '600px',
    height: '161px',
    display:'flex',
    alignItems:'center',
    justifyContent: 'center',
  },
  findOrderByNumber: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  findOrderButtonAndField: {
    gap: 10,
    paddingTop: 5,
    display: 'flex',
    alignItems:'center',
    width: '90%',
  },
  findOrderByEmail:{
    display:'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 5,
  },
  emailCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap:5,
  },
  roleButtons: {
    margin: 'auto',
    display: 'block',
    marginBottom: '0.7em',
    marginTop: 0,
  },
}))

function ClaimOrderStep1(props) {
  const [hoveredOrder, setHoveredOrder] = React.useState(null)

  const getBackgroundColor = (deselected, hovered) => {
    let color = '#c9c9c9'
    if (deselected) {
      color = '#f7f7f7'
    }
    if (hovered){
      color = adjustColor(color, -25)
    }
    return color
  }

  return (
    <>
      {props.reviewType === 'email' || props.orderContent.length === 0 ? null :
        <SKUCard skuItems={props.orderContent.skuItems}/>}
      {props.reviewType === 'email' ?
        <div className={props.classes.emailCardContainer}>
          {props.orderEmailData.map((order) => {
            const deselected = props.deselectedOrders.find(orderId => orderId === order.id)
            const hovered = order.id === hoveredOrder

            return (
              <ButtonBase
                key={order.id}
                disabled={props.disabled}
                disableRipple
                onMouseEnter={() => setHoveredOrder(order.id)}
                onMouseLeave={() => setHoveredOrder(null)}
                onClick={()=> props.handleSelectedOrder(deselected, order.id)}
                style={{padding: '16px 16px 16px 0px', borderRadius: 6, backgroundColor: getBackgroundColor(deselected, hovered), width: '100%'}}
              >
                <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                  {deselected ?
                    <RadioButtonUncheckedIcon color='primary' style={{margin: '16px 24px 16px 24px'}}/>:
                    <RadioButtonCheckedIcon color='primary' style={{margin: '16px 24px 16px 24px'}}/>
                  }
                  <div style={{width: '100%'}}>
                    <div style={{display: 'flex', paddingBottom: 5}}>
                    </div>
                    <SKUCard skuItems={order.skuItems}/>
                  </div>
                </div>
              </ButtonBase>
            )
          })}
        </div>:
        null}
    </>
  )
}

export default function ClaimOrderDialog(props) {
  const classes = useStyles()
  const [orderNumberField, setOrderNumberField] = React.useState('')
  const [orderContent, setOrderContent] = React.useState([])
  const [submitFetchStatus, setSubmitFetchStatus] = React.useState(null)
  const [claimFetchStatus, setClaimFetchStatus] = React.useState(null)
  const [activeStep, setActiveStep] = React.useState(0)
  const [reviewType, setReviewType] = React.useState('number')
  const [deselectedOrders, setDeselectedOrders] = React.useState([])
  const [generateClassSuccess, setGenerateClassSuccess] = React.useState(false)
  const showEmailOrders = props.getOrdersByEmailStatus !== 'waiting' && props.orderEmailData.length > 0

  const handleSubmitButton = async () => {
    setOrderContent([])
    return props.requestOrder(orderNumberField.trim())
  }

  const handleSelectedOrder = (deselected, orderId) => {
    const deselectedOrdersCopy = [...deselectedOrders]

    if (deselected){
      const index = deselectedOrdersCopy.indexOf(orderId)
      if (index > -1) {
        deselectedOrdersCopy.splice(index, 1)
      }
    } else {
      deselectedOrdersCopy.push(orderId)
    }
    setDeselectedOrders(deselectedOrdersCopy)
  }

  const handleSubmitButtonSuccess = (msg) => {
    setOrderContent(msg)
    setReviewType('number')
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const getClaimedLicenses = () => {
    let id = []
    if (reviewType === 'number'){
      id = [orderNumberField.trim()]
    } else {
      id = props.orderEmailData.filter(x => !deselectedOrders.includes(x.id)).map(order => order.number.trim())
    }
    return id
  }

  const handleClaimButton = async () => {
    let id = getClaimedLicenses()
    return props.claimOrder(id)
  }

  const handleClaimButtonSuccess = async (msg) => {
    props.getLicenses()
    handleClose()
  }

  const handleClose = () => {
    if (claimFetchStatus === 'waiting') {
      return
    }
    setGenerateClassSuccess(false)
    setDeselectedOrders([])
    setSubmitFetchStatus(null)
    setClaimFetchStatus(null)
    setActiveStep(0)
    setOrderNumberField('')
    setOrderContent([])
    props.handleClose()
  }

  return (
    <GenericDialog
      open={props.open}
      onClose={handleClose}
      title={
        <Typography>
          {'Claim Your License'}
        </Typography>
      }
      buttons={
        <>
          { activeStep === 3 && !generateClassSuccess ?
            <Button
              variant='outlined'
              onClick={() => setActiveStep(prevActiveStep => prevActiveStep - 1)}
            >
              Back
            </Button>:
            null
          }
          { activeStep === 1 ?
            <RespButton
              variant='contained'
              style={{backgroundColor: submitFetchStatus === 'success' ? '#388333' : 'grey', color:'white'}}
              buttonComponent={Button}
              onClick={handleClaimButton}
              successCb={handleClaimButtonSuccess}
              status={claimFetchStatus}
              setStatus={setClaimFetchStatus}
              disabled={submitFetchStatus === 'waiting' || reviewType === 'email' ? props.orderEmailData.length === deselectedOrders.length : false}
            >
              {`Claim ${reviewType === 'number' ? 'Licenses' : (props.orderEmailData.length - deselectedOrders.length) +' Licenses'}`}
            </RespButton>:
            null
          }
          <Button
            variant='outlined'
            onClick={handleClose}
            disabled={claimFetchStatus === 'waiting'}
          >
            {activeStep === 3 ? 'Close' : 'Cancel'}
          </Button>
        </>
      }
    >
      <>
        {props.getOrdersByEmailStatus !== 'waiting' ?
          <div style={{width: showEmailOrders ? '700px' : '600px'}} className={classes.dialogContent}>
            {activeStep === 0 ?
              <div className={classes.firstStepContainer}>
                {showEmailOrders ?
                  <div className={classes.findOrderByEmail}>
                    <Typography>
                      {`We've found ${props.orderEmailData.length} order${props.orderEmailData.length > 1 ? 's' : ''} associated with your email!`}
                    </Typography>
                    <Button
                      onClick={()=>{
                        setActiveStep(prevActiveStep => prevActiveStep + 1);setReviewType('email')
                      }}
                      variant='outlined'
                    >
                      {`Show Order${props.orderEmailData.length > 1 ? 's' : ''}`}
                    </Button>
                  </div>:
                  null}
                {showEmailOrders ?
                  <div style={{margin: 'auto'}}> OR </div>:
                  null}
                <div className={classes.findOrderByNumber}>
                  <Typography>
                    Find Order By Claim Code:
                  </Typography>
                  <div className={classes.findOrderButtonAndField}>
                    <OutlinedTextField
                      style={{flexGrow: 1, marginTop: '5px'}}
                      label={'Claim Code'}
                      onChange={ev=>setOrderNumberField(ev.target.value)}
                      value={orderNumberField}
                    />
                    <RespButton
                      style={{height: 45, marginTop: 4}}
                      buttonComponent={Button}
                      onClick={handleSubmitButton}
                      successCb={handleSubmitButtonSuccess}
                      variant='outlined'
                      status={submitFetchStatus}
                      setStatus={setSubmitFetchStatus}
                      disabled={claimFetchStatus === 'waiting' || orderNumberField === ''}
                      leftIndicator
                    >
                      Submit Claim Code
                    </RespButton>
                  </div>
                </div>
              </div>: null}
            {activeStep === 1 ?
              <ClaimOrderStep1
                {...props}
                classes={classes}
                reviewType={reviewType}
                orderContent={orderContent}
                deselectedOrders={deselectedOrders}
                disabled={claimFetchStatus === 'waiting'}
                handleSelectedOrder={handleSelectedOrder}/>:
              null}
            <div></div>
          </div>:
          <div className={classes.circularProgressContainer}>
            <CircularProgress/>
          </div>
        }
      </>
    </GenericDialog>
  )
}
