import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Drawer from '@material-ui/core/Drawer'
import { actionPanelHeaderHeight, rightDrawerWidth  } from './App'
import Typography from '@material-ui/core/Typography'
import { DeleteButton, RespButton } from './common/buttons'

const hackValueHeight = '7.2rem'

const useStyles = makeStyles(theme => ({
  paper: {
    flexShrink: 0,
    top: actionPanelHeaderHeight,
    width: rightDrawerWidth,
    height: 'unset',
    backgroundColor: '#8d8d8d',
    borderLeft: '0px',
  },
  drawerHeader: {
    height: 50,
    display:'flex',
    alignItems:'center',
    justifyContent: 'space-between',
    padding: 5,
  },
  closeButton: {
    borderRadius: 4,
    width: 50,
  },
  deleteButton: {
    backgroundColor: 'red',
  },
  deleteContainer: {
    padding: '25px',
    display:'flex',
    flexDirection: 'column',
    gap: 25,
  },
}))

export function DeleteActionPanel(props){
  const [fetchStatus, setFetchStatus] = React.useState(null)
  const classes = useStyles()

  return (
    <div className={classes.deleteContainer}>
      <ActionPanelHeader
        title={props.title}
        waiting={props.waiting}// fetchStatus === 'waiting'}
        handleClose={props.handleClose}
      />
      <RespButton
        color='secondary'
        buttonComponent={DeleteButton}
        onClick={props.handleDelete}
        successCb={props.handleDeleteSuccess}
        status={fetchStatus}
        setStatus={setFetchStatus}
        symmetrical={true}
        disabled={props.rowsSelected === 0}
      >
        {`Delete ${props.rowsSelected} ${props.type}`}
      </RespButton>
    </div>
  )
}


export const ActionPanelHeader = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.drawerHeader}>
      <div style={{width: 50}}></div>
      <Typography>
        {props.title}
      </Typography>
      <div style={{width: 50}}>
        <IconButton
          className={classes.closeButton}
          onClick={props.handleClose}
          disabled={props.waiting}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  )
}

export default function ActionPanel(props) {
  const classes = useStyles()

  return (
    <div id={'drawer-container'}>
      <Drawer
        classes={{paper: classes.paper}}
        open={props.open}
        onClose={props.handleClose}
        anchor='right'
        PaperProps={{
          style: {
            width: props.open ? rightDrawerWidth : 0,
            position: 'absolute',
            maxWidth: props.open ? rightDrawerWidth : 'initial',
            border: 'none',
            height: `calc(100vh - ${actionPanelHeaderHeight})`,
          },
        }}
        ModalProps={{
          container: document.getElementById('drawer-container'),
          disableEnforceFocus: true,
          style: { position: 'absolute' },
        }}
        variant='persistent'
      >
        <div
          style={{
            height:`calc(100vh - ${actionPanelHeaderHeight})`,
            minHeight: `calc(${actionPanelHeaderHeight} + ${hackValueHeight})`,
            borderBottom: '1px solid black',
          }}
        >
          {props.children}
        </div>
      </Drawer>
    </div>
  )
}
