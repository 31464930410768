import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Typography } from '@material-ui/core'
import GenericDialog from './common/GenericDialog'
import { OutlinedTextField } from './common/fields'
import { RespButton, FiriaGreenButton, DeleteButton } from './common/buttons'
import { Cell } from './common/SKUDecoder'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles(theme => ({
  backdrop: {
    position: 'absolute',
    zIndex: 1,
  },
  progress: {
    position:'absolute',
    color: 'black',
  },
  loadingPanel: {
    position: 'fixed',
    backgroundColor: 'rgba(0,0,0,0.1)',
    zIndex: 1500,
  },
  sectionPanelContainer: {
    border: '1px solid rgba(0,0,0,0.3)',
    borderRadius: 3,
    marginTop: 3,
  },
  sectionPanelTitle: {
    padding: '0px 6px',
    position: 'absolute',
    borderRadius: 3,
    backgroundColor: 'white',
    border: '1px solid rgba(0,0,0,0.4)',
    borderBottom: '1px solid rgba(0,0,0,0.4)',
    marginLeft: -1,
    top: 50,
  },
  deleteButtonRow: {
    height: 37,
    width: '100%',
    borderRadius: 0,
  },
}))

const SectionPanel = (props) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.sectionPanelContainer}>
        <Typography variant='body2' className={classes.sectionPanelTitle}>
          {props.title}
        </Typography>
        <div style={{ backgroundColor: 'rgba(0,0,0,0.007)', padding: '20px 5px 5px'}}>
          <div style={{marginBottom: 7}}></div>
          {props.children}
        </div>
      </div>
    </>
  )
}

const DeleteDialog = (props) => {
  const [fetchStatus, setFetchStatus] = React.useState(null)

  const handleClick = () => {
    return props.post()
  }

  const successCb = () => {
    props.refresh()
    props.handleClose()
  }

  return (
    <GenericDialog
      open={props.open}
      onClose={props.handleClose}
      title={
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
          <Typography>
            {props.admin ? 'Delete Admin Confirmation' : 'Delete User Confirmation'}
          </Typography>
        </div>
      }
      buttons={
        <>
          <RespButton
            buttonComponent={DeleteButton}
            onClick={handleClick}
            successCb={successCb}
            status={fetchStatus}
            setStatus={setFetchStatus}
            resetStatus={!props.open}
          >
            {`Delete ${props.admin ? 'Admin' : 'User'}`}
          </RespButton>
          <Button
            onClick={props.handleClose}
            variant='outlined'
          >
            Close
          </Button>
        </>
      }
    >
      <div style={{display:'flex', gap: 20, padding: '5px 10px'}}>
        <Typography>
          {`Are you sure you want to delete ${props.admin ? 'admin' : 'user'} ${props.email}?`}
        </Typography>
      </div>
    </GenericDialog>
  )
}

const LoadingPanel = ({ top, left, height, width }) => {
  const classes = useStyles()

  return (
    <div className={classes.loadingPanel} style={{top: top, left: left, height: height, width: width}}>
      <CircularProgress className={classes.progress} style={{top: (height/2)-75, left: (width/2)-20}}/>
    </div>
  )
}

export default function ShareLicensesDialog(props) {
  const classes = useStyles()
  const open = props.open
  const [recipientEmailField, setRecipientEmailField] = React.useState('')
  const [fetchStatus, setFetchStatus] = React.useState(null)
  const [userDialogOpen, setUserDialogOpen] = React.useState(false)
  const [adminDialogOpen, setAdminDialogOpen] = React.useState(false)
  const [bodyRect, setBodyRect] = React.useState({})
  const selectedEmail = React.useRef(null)
  const bodyRef = React.useRef(null)
  const assignedLicense = props.selectedLicense?.row?.type === 'Assigned'
  const licenseFetchStatus = props.requestLicenseFetchStatus

  React.useEffect(() => {
    if (!open){
      selectedEmail.current = null
      setBodyRect({})
    }
  }, [open])

  React.useEffect(() => {
    const createBodyProgressPanel = () => {
      if (!bodyRef.current || licenseFetchStatus !== 'waiting'){
        return
      }

      if (licenseFetchStatus === 'waiting'){
        const rect = bodyRef.current.getBoundingClientRect()
        setBodyRect(rect)
      }
    }

    createBodyProgressPanel()
  }, [licenseFetchStatus])

  const handleShareLicenseClick = () => {
    return props.shareLicenses(recipientEmailField.trim().toLowerCase(), [props.selectedLicense?.id])
  }

  const handleShareLicenseSuccess = () => {
    setRecipientEmailField('')
    props.setLicenseData(props.selectedLicense?.id)
  }

  const handleUserDelete = (email) => {
    selectedEmail.current = email
    setUserDialogOpen(true)
  }

  const handleAdminDelete = (email) => {
    selectedEmail.current = email
    setAdminDialogOpen(true)
  }

  return (
    <GenericDialog
      open={props.open}
      onClose={props.handleClose}
      dialogRoot={classes.dialogRoot}
      keepMounted
      title={
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
          <Typography>
            Add License Admin
          </Typography>
          <Typography variant='body2'>
            {props.selectedLicense?.id}
          </Typography>
        </div>
      }
      buttons={
        // !props.selectedLicense.row?.expiration && props.selectedLicense.row?.expires ?
        //   <div style={{display: 'flex', flexDirection: 'row', alignItems: 'space-between', justifyContent: 'space-between', width: '100%'}}>
        //     <props.ActivateLicenseButton onClick={() => props.setSelectedActivateLicenseData(props.selectedLicense)} />
        //     <Button
        //       onClick={props.handleClose}
        //       variant='outlined'
        //     >
        //     Close
        //     </Button>
        //   </div>:
        <Button
          onClick={props.handleClose}
          variant='outlined'
        >
          Close
        </Button>
      }
    >
      <div ref={bodyRef} style={{display:'flex', gap: 20}}>
        <SectionPanel title='Admins' fetchStatus={props.requestLicenseFetchStatus}>
          <div style={{display:'flex', alignItems:'center', gap: 5, marginBottom: 10}}>
            <OutlinedTextField
              style={{flexGrow: 1, marginTop: 5}}
              label={'Recipient\'s Email'}
              onChange={ev=>setRecipientEmailField(ev.target.value)}
              value={recipientEmailField}
              disabled={props.requestLicenseFetchStatus === 'waiting'}
            />
            <RespButton
              style={{height: '44px'}}
              buttonComponent={FiriaGreenButton}
              onClick={handleShareLicenseClick}
              successCb={handleShareLicenseSuccess}
              status={fetchStatus}
              setStatus={setFetchStatus}
              disabled={recipientEmailField === '' || props.requestLicenseFetchStatus === 'waiting'}
              resetStatus={!props.open}
              leftIndicator
            >
            Share License
            </RespButton>
          </div>
          <TableContainer square variant='outlined' style={{width: '440px', height: '405px'}} component={Paper}>
            <Table size='small' stickyHeader>
              <TableHead >
                <TableRow className={classes.skuCardHeader} style={{fontSize: 14, height:37}}>
                  <Cell >Email</Cell>
                  <Cell >Name</Cell>
                  <Cell >Delete</Cell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.requestLicenseFetchStatus !== 'waiting' ?
                  props.requestLicenseData?.adminUserList.map((row, index) => {
                    return (
                      <TableRow key={index} className={classes.skuCardPaper}>
                        <Cell >{row.email}</Cell>
                        <Cell >{row.name}</Cell>
                        <Cell style={{padding: 0}}>
                          <IconButton
                            className={classes.deleteButtonRow}
                            disabled={row.email === props.user?.email}
                            onClick={()=>handleAdminDelete(row.email)}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </Cell>
                      </TableRow>
                    )
                  }):
                  null
                }
              </TableBody>
            </Table>
          </TableContainer>
        </SectionPanel>
        {assignedLicense ?
          <SectionPanel title='Users' fetchStatus={props.requestLicenseFetchStatus}>
            <TableContainer square variant='outlined' style={{width: '440px', height: '465px'}} component={Paper}>
              <Table size='small' stickyHeader>
                <TableHead >
                  <TableRow className={classes.skuCardHeader} style={{fontSize: 14, height:37}}>
                    <Cell >Email</Cell>
                    <Cell >Name</Cell>
                    <Cell >Delete</Cell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.requestLicenseFetchStatus !== 'waiting' ?
                    props.requestLicenseData?.userList.map((row, index) => {
                      return (
                        <TableRow key={index} className={classes.skuCardPaper}>
                          <Cell >{row.email}</Cell>
                          <Cell >{row.name}</Cell>
                          <Cell style={{padding: 0}}>
                            <IconButton
                              className={classes.deleteButtonRow}
                              onClick={()=>handleUserDelete(row.email)}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Cell>
                        </TableRow>
                      )
                    }):
                    null
                  }
                </TableBody>

              </Table>
            </TableContainer>
          </SectionPanel>:
          null}
      </div>
      {props.requestLicenseFetchStatus === 'waiting' ?
        <LoadingPanel top={bodyRect?.top} left={bodyRect?.left} width={bodyRect?.width} height={bodyRect?.height}/>:
        null}
      <DeleteDialog
        open={userDialogOpen}
        handleClose={()=>setUserDialogOpen(false)}
        post={() => props.removeMemberFromLicense(props.selectedLicense?.id, selectedEmail.current)}
        refresh={()=>props.setLicenseData(props.selectedLicense?.id)}
        email={selectedEmail.current}
      />
      <DeleteDialog
        open={adminDialogOpen}
        handleClose={()=>setAdminDialogOpen(false)}
        admin
        post={() => props.removeAdminFromLicense(props.selectedLicense?.id, selectedEmail.current)}
        refresh={()=>props.setLicenseData(props.selectedLicense?.id)}
        email={selectedEmail.current}
      />
    </GenericDialog>
  )
}
