import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { Typography } from '@material-ui/core'
import GenericDialog from './common/GenericDialog'
import { RespButton, FiriaGreenButton } from './common/buttons'

import { useSnackbar } from 'notistack'

const useStyles = makeStyles(theme => ({
  backdrop: {
    position: 'absolute',
    zIndex: 1,
  },
}))

const ActivateLicenseDialog = ({ activateLicense, licenseData, closeDialog, missionPacks, getLicenses }) => {
  const [fetchStatus, setFetchStatus] = React.useState(null)
  const dataCopy = React.useRef(null)
  if (licenseData){
    dataCopy.current = licenseData
  }

  const classes = useStyles()
  const id = dataCopy.current?.id
  const data = dataCopy.current?.row
  const open = Boolean(licenseData)

  const snacks = useSnackbar()
  const onFetchSuccess = () => {
    snacks.enqueueSnackbar('Successfully activated license.', {
      variant: 'success',
    })
    getLicenses()
    closeDialog()
  }

  const fields = [
    {title: 'License Id', value: id},
    {title: 'Product', value: missionPacks.find(pack => pack.id === data?.missionPackId)?.title},
    {title: 'Claimed On', value: new Date(data?.created._seconds*1000 + data?.created._nanoseconds/100000).toLocaleString()},
    {title: 'Term (days)', value: data?.licenseTerm},
    {title: 'Seats', value: data?.seats},
  ]

  return (
    <GenericDialog
      open={open}
      onClose={closeDialog}
      dialogRoot={classes.dialogRoot}
      keepMounted
      buttons={
        <Button
          onClick={closeDialog}
          variant='outlined'
        >
            Cancel
        </Button>
      }
      title={
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
          <Typography>
            {'Activate License'}
          </Typography>
        </div>
      }
    >
      <div style={{padding: 20}}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center', gap: 10}}>
          <div>
            {fields.map(({ title }) =>
              <Typography style={{display: 'flex', justifyContent:'flex-end', alignItems:'center', fontWeight: 'bold' }}>
                {title+': '}
              </Typography>
            )}
          </div>
          <div>
            {fields.map(({ value }) =>
              <Typography>
                {value}
              </Typography>
            )}
          </div>
        </div>
        <div style={{display: 'flex', alignItems:'center', justifyContent: 'center', flexDirection: 'column', paddingTop: 20}}>
          <Typography>
            {'If activated, your license will expire on: '}
          </Typography>
          <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>
            {new Date( Date.now() + data?.licenseTerm * 24 * 60 * 60 * 1000).toLocaleDateString()}
          </Typography>
          <div style={{ marginTop: 15 }}></div>
          <RespButton
            style={{height: '44px'}}
            buttonComponent={FiriaGreenButton}
            onClick={() => activateLicense(id)}
            symmetrical
            leftIndicator
            successCb={onFetchSuccess}
            status={fetchStatus}
            setStatus={setFetchStatus}
            resetStatus={!open}
          >
            Activate License
          </RespButton>
        </div>
      </div>
    </GenericDialog>
  )
}

export default ActivateLicenseDialog