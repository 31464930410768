import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { Tooltip, Badge } from '@material-ui/core'

import { adjustColor } from './common/utils'

const useStyles = makeStyles(theme => ({
  claimLicenseButton: {
    backgroundColor: theme.palette.custom.secondary,
    color: 'black',
    whiteSpace: 'nowrap',
    height: 44,
    minWidth: 'max-content',
    '&:hover': {
      backgroundColor: adjustColor(theme.palette.custom.secondary, -10),
    },
  },
}))

const ClaimButton = ({ unclaimedAssociatedOrders, openClaimOrderDialog }) => {
  const classes = useStyles()

  return (
    <Tooltip
      title='Open Filter Panel'
      placement='top'
      arrow
    >
      <Badge badgeContent={unclaimedAssociatedOrders} color='primary'>
        <Button
          variant='contained'
          className={classes.claimLicenseButton}
          size='large'
          onClick={openClaimOrderDialog}
        >
          CLAIM ORDER
        </Button>
      </Badge>
    </Tooltip>
  )
}

export default ClaimButton