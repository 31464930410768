import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { RespButton, FiriaGreenButton } from './common/buttons'
import { OutlinedTextField } from './common/fields'
import { Typography } from '@material-ui/core'
import GenericDialog from './common/GenericDialog'

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
    marginTop: '2px',
  },
  textFieldContainer: {
    width: '400px',
    padding: '6px 0px',
  },
}))

export default function ClaimLicenseDialog(props) {
  const classes = useStyles()
  const [licenseIdField, setLicenseIdField] = React.useState('')
  const [fetchStatus, setFetchStatus] = React.useState(null)

  const handleClaimButton = async () => {
    return props.claimLicenseKey(licenseIdField.trim())
  }

  const handleClaimButtonSuccess = () => {
    props.getLicenses()
    setLicenseIdField('')
    props.handleClose()
  }

  return (
    <GenericDialog
      open={props.open}
      onClose={props.handleClose}
      title={
        <Typography>
          Claim License Key
        </Typography>
      }
      buttons={
        <>
          <RespButton
            variant='contained'
            buttonComponent={FiriaGreenButton}
            onClick={handleClaimButton}
            successCb={handleClaimButtonSuccess}
            status={fetchStatus}
            setStatus={setFetchStatus}
            disabled={licenseIdField === ''}
            resetStatus={!props.open}
          >
            Claim Licenses
          </RespButton>
          <Button
            onClick={props.handleClose}
            variant='outlined'
          >
            Close
          </Button>
        </>
      }
    >
      <div className={classes.textFieldContainer}>
        <OutlinedTextField
          className={classes.textField}
          label={'License Key'}
          onChange={ev=>setLicenseIdField(ev.target.value)}
          value={licenseIdField}
        />
      </div>
    </GenericDialog>
  )
}
